const loadDynatraceScript = (env) => {
    const dynatraceUrls = {
        dev: 'https://js-cdn.dynatrace.com/jstag/1624d0f62e6/bf01571vpf/b2b2dec5e8c4d866_complete.js',
        int: 'https://js-cdn.dynatrace.com/jstag/1624d0f62e6/bf01571vpf/bc4d82bfabab7509_complete.js',
        pp: 'https://js-cdn.dynatrace.com/jstag/1624d0f62e6/bf01571vpf/4ee17e94e23251ae_complete.js',
        prod: 'https://js-cdn.dynatrace.com/jstag/1624d0f62e6/bf01571vpf/6756d48367df301_complete.js',
    };

    const scriptUrl = dynatraceUrls[env];

    if (scriptUrl) {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.async = true;
        document.head.appendChild(script);
        console.log(
            `Successfully loaded Dynatrace script for ${env} environment`,
        );
    } else {
        console.error(`Failed to load Dynatrace script for ${env} environment`);
        console.error(
            `Invalid environment "${env}". Expected one of: ${Object.keys(
                dynatraceUrls,
            ).join(', ')}`,
        );
    }
};

export default loadDynatraceScript;
